import { Link } from "gatsby";
import React from "react";
import HashLoader from "react-spinners/HashLoader";
import DashboardStationHeader from "../../components/dashboardStationHeader";
import Dropdown from "../../components/dropdown";
import Footer from "../../components/footer";
import Header from "../../components/header";
import DateRange from "../../components/models/dateRange";
import Map from "../../components/models/map";
import Toggle from "../../components/models/toggle";
import Seo from "../../components/seo";
import StationsDropdownV2 from "../../components/stationsDropdownV2";
import Subheader from "../../components/subHeader";
import Transition from "../../components/transition";
import { useAuth0 } from "../../context/react-auth0-spa";
import NewaLogo from "../../svg/newa-logo.svg";
import useFetchStationDataAWDQ from "../../utils/hooks/useFetchStationDataAWDQ";
import { useLocalStorage } from "../../utils/hooks/useLocalStorage";
import useStations from "../../utils/hooks/useStations";
import modelLogic from "./_modelLogic";
import ResultsGraph from "./_resultsGraph";
import ResultsTable from "./_resultsTable";
import modelData from "./degree-day-calculator.json";

const ddTypes = [
  {
    formula: "max/min_average",
    base: 0,
    unit: "C",
    id: 18,
    label: "Base 0˚C ",
  },
  {
    formula: "max/min_average",
    base: 1,
    unit: "C",
    id: 19,
    label: "Base 1˚C ",
  },
  {
    formula: "max/min_average",
    base: 4,
    unit: "C",
    id: 1,
    label: "Base 4˚C ",
  },
  {
    formula: "max/min_average",
    base: 10.4,
    unit: "C",
    id: 20,
    label: "Base 10.4˚C",
  },
  {
    formula: "max/min_average",
    base: 14.3,
    unit: "C",
    id: 13,
    label: "Base 14.3˚C",
  },
  {
    formula: "BE",
    base: 4,
    unit: "C",
    id: 21,
    label: "Base 4˚C BE (sine wave)",
  },
  {
    formula: "max/min_average",
    base: 32,
    unit: "F",
    id: 2,
    label: "Base 32˚F",
  },
  {
    formula: "max/min_average",
    base: 37.5,
    unit: "F",
    id: 23,
    label: "Base 37.5˚F",
  },
  {
    formula: "max/min_average",
    base: 39,
    unit: "F",
    id: 3,
    label: "Base 39˚F",
  },
  {
    formula: "max/min_average",
    base: 40,
    unit: "F",
    id: 4,
    label: "Base 40˚F",
  },
  {
    formula: "max/min_average",
    base: 41,
    unit: "F",
    id: 5,
    label: "Base 41˚F",
  },
  {
    formula: "max/min_average",
    base: 43,
    unit: "F",
    id: 6,
    label: "Base 43˚F",
  },
  {
    formula: "max/min_average",
    base: 45,
    unit: "F",
    id: 7,
    label: "Base 45˚F",
  },
  {
    formula: "max/min_average",
    base: 47.14,
    unit: "F",
    id: 12,
    label: "Base 47.14˚F",
  },
  {
    formula: "max/min_average",
    base: 48,
    unit: "F",
    id: 8,
    label: "Base 48˚F",
  },
  {
    formula: "max/min_average",
    base: 50,
    unit: "F",
    id: 9,
    label: "Base 50˚F",
  },
  {
    formula: "max/min_average",
    base: 55,
    unit: "F",
    id: 11,
    label: "Base 55˚F",
  },
  {
    formula: "max/min_average",
    base: 50,
    unit: "F",
    id: 10,
    label: "Degree Days - 86/50˚F",
  },
  {
    formula: "BE",
    base: 37.5,
    unit: "F",
    id: 22,
    label: "Base 37.5˚F BE (sine wave)",
  },
  {
    formula: "BE",
    base: 43,
    unit: "F",
    id: 15,
    label: "Base 43˚F BE (sine wave)",
  },
  {
    formula: "BE",
    base: 45,
    unit: "F",
    id: 16,
    label: "Base 45˚F BE (sine wave)",
  },
  {
    formula: "BE",
    base: 50,
    unit: "F",
    id: 17,
    label: "Base 50˚F BE (sine wave)",
  },
];

export default function DegreeDayCalculator() {
  const { title, description } = modelData;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [isMesonet, setIsMesonet] = React.useState(false);
  const [isTableData, setIsTableData] = React.useState(true);
  const [isGraphData, setIsGraphData] = React.useState(true);
  const [showMap, setShowMap] = React.useState(false);

  const [degreeDayType, setDegreeDayType] = useLocalStorage(
    "newaDegreeDayCalculatorType",
    ddTypes[8]
  );

  // Function to set the endDate to the end of the day
  const setEndDateToEndOfDay = (date) => {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999); // Set to 23:59:59.999
    return endOfDay;
  };

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [modifiedEndDate, setModifiedEndDate] = React.useState(null);

  // When the endDate is set, update the modifiedEndDate to the end of the day
  React.useEffect(() => {
    if (endDate) {
      const currentDate = new Date();
      const selectedDate = new Date(endDate);

      // Check if selectedDate is note the current date
      if (
        selectedDate.getFullYear() !== currentDate.getFullYear() ||
        selectedDate.getMonth() !== currentDate.getMonth() ||
        selectedDate.getDate() !== currentDate.getDate()
      ) {
        // If it's not the current date, add one day
        selectedDate.setDate(selectedDate.getDate() + 1);
      }
      setModifiedEndDate(selectedDate);
    }
  }, [endDate]);

  const {isLoading, data} = useFetchStationDataAWDQ(station, startDate, setEndDateToEndOfDay(modifiedEndDate))

  let mData = null;
  if (data) {
    mData = modelLogic(data, degreeDayType);
  }

  const isVisible = station && mData;

  React.useEffect(() => {
    if (station === null) return;

    if (station.id.includes("nysm")) {
      setIsMesonet(true);
      setIsTableData(false);
      setIsGraphData(false);
    } else {
      setIsMesonet(false);
      setIsTableData(true);
      setIsGraphData(true);
    }
  }, [station, setIsMesonet, setIsTableData, setIsGraphData]);

  return (
    <>
      <Seo title={title} />
      <div className="flex h-screen overflow-hidden bg-gray-50">
        {/* <!-- Off-canvas menu for mobile --> */}
        <div className="lg:hidden">
          <div className={`fixed inset-0 flex ${sidebarOpen ? `z-40` : `z-0`}`}>
            <Transition
              show={sidebarOpen}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0">
                <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
              </div>
            </Transition>

            <Transition
              show={sidebarOpen}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col flex-1 w-full max-w-xs bg-white">
                <div className="absolute top-0 right-0 p-1 -mr-14">
                  <button
                    onClick={() => setSidebarOpen(false)}
                    className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
                    aria-label="Close sidebar"
                  >
                    <svg
                      className="w-6 h-6 text-white"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex items-center flex-shrink-0 px-4">
                    <div className="flex flex-col items-center w-full mb-8 lg:hidden">
                      <div className="pb-2">
                        <Link
                          to={isAuthenticated ? "/user" : "/"}
                          className="text-sm font-semibold leading-6 tracking-wider text-gray-600 no-underline lg:text-lg hover:underline"
                        >
                          {isAuthenticated ? "Dashboard" : "Home"}
                        </Link>
                      </div>
                      <div className="py-2">
                        <Link
                          to="/weather-tools"
                          className="text-sm font-semibold leading-6 tracking-wider text-gray-600 no-underline lg:text-lg hover:underline"
                        >
                          Weather Tools
                        </Link>
                      </div>
                      <div className="py-2">
                        <Link
                          to="/crop-and-pest-management"
                          className="text-sm font-semibold leading-6 tracking-wider text-gray-600 no-underline lg:text-lg hover:underline"
                        >
                          Crop & IPM Tools
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="px-2 mt-3">
                    <Link
                      to={isAuthenticated ? "/user" : "/"}
                      className="hidden lg:inline-block lg:w-28 xl:w-40"
                    >
                      <NewaLogo></NewaLogo>
                    </Link>
                    <div className="flex items-center justify-center mt-6 uppercase">
                      <a
                        className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white no-underline border border-transparent rounded-md shadow-sm bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 hover:text-white"
                        href={`https://newa.zendesk.com/hc/en-us/articles/1500003853241`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        watch tutorial
                        <svg
                          className="ml-2 -mr-0.5 h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                          <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                        </svg>
                      </a>
                    </div>
                    <div className="relative z-10">
                      <StationsDropdownV2
                        options={user ? favoriteStations : stationList}
                        value={station}
                        setValue={setStation}
                        isMobile={true}
                        setSidebarOpen={setSidebarOpen}
                      ></StationsDropdownV2>
                    </div>

                    <hr className="w-3/4 mx-auto mt-16"></hr>
                    <div className="mt-6">
                      <DateRange
                        startYear={
                          station?.startYear || new Date().getFullYear()
                        }
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                      ></DateRange>
                    </div>

                    <hr className="w-3/4 mx-auto mt-16"></hr>
                    <div className="mt-6">
                      <Dropdown
                        selected={degreeDayType}
                        setSelected={setDegreeDayType}
                        list={ddTypes}
                        label={"Degree Days Type"}
                      ></Dropdown>
                    </div>

                    <hr className="w-3/4 mx-auto mt-16"></hr>
                    <div className="mt-6">
                      <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                        Show/Hide
                      </span>
                      <span>
                        <Toggle
                          text="Station Selection Map"
                          showItem={showMap}
                          setShowItem={setShowMap}
                        ></Toggle>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <div className="flex-shrink-0 w-14">
              {/* <!-- Force sidebar to shrink to fit close icon --> */}
            </div>
          </div>
        </div>

        {/* <!-- Static sidebar for desktop --> */}
        <div className="relative hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col bg-white border-r border-gray-200 w-72">
            <div className="flex flex-col flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
              <div className="flex-1 px-2 bg-white">
                <div className="flex justify-center">
                  <Link
                    to={isAuthenticated ? "/user" : "/"}
                    className="hidden lg:inline-block lg:w-28 xl:w-40"
                  >
                    <NewaLogo></NewaLogo>
                  </Link>
                </div>

                <div className="flex items-center justify-center mt-6 uppercase">
                  <a
                    className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white no-underline border border-transparent rounded-md shadow-sm bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 hover:text-white"
                    href={`https://newa.zendesk.com/hc/en-us/articles/1500003853241`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    watch tutorial
                    <svg
                      className="ml-2 -mr-0.5 h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                    </svg>
                  </a>
                </div>
                <div className="mt-16">
                  <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                    {isAuthenticated ? "Favorite Stations" : "All Stations"}
                  </span>
                  <StationsDropdownV2
                    options={user ? favoriteStations : stationList}
                    value={station}
                    setValue={setStation}
                  ></StationsDropdownV2>
                </div>

                <hr className="w-3/4 mx-auto mt-16"></hr>
                <div className="mt-6">
                  <DateRange
                    startYear={station?.startYear || new Date().getFullYear()}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  ></DateRange>
                </div>

                <hr className="w-3/4 mx-auto mt-16"></hr>
                <div className="mt-6">
                  <Dropdown
                    selected={degreeDayType}
                    setSelected={setDegreeDayType}
                    list={ddTypes}
                    label={"Degree Day Type"}
                  ></Dropdown>
                </div>
                <div>
                  <br></br>
                  <p>
                    Also try the <b>Cornell Climate Smart Farming</b>{" "}
                    <a
                      href="http://climatesmartfarming.org/tools/csf-growing-degree-day-calculator/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Degree Day Calculator
                    </a>
                  </p>
                </div>

                <hr className="w-3/4 mx-auto mt-16"></hr>
                <div className="mt-6">
                  <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
                    Show/Hide
                  </span>
                  <span>
                    <Toggle
                      text="Station Selection Map"
                      showItem={showMap}
                      setShowItem={setShowMap}
                    ></Toggle>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className={`flex flex-col w-0 flex-1 overflow-hidden z-0`}>
          <div
            className={`lg:hidden flex items-center justify-between pl-1 pt-1 xl:pl-3 xl:pt-3 pr-4 `}
          >
            <button
              onClick={() => setSidebarOpen(true)}
              className={`-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150`}
              aria-label="Open sidebar"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
            <h2 className={`text-lg font-semibold tracking-wide text-gray-600`}>
              {title}
            </h2>
          </div>

          <main
            className="relative flex-1 h-screen overflow-y-auto z-1 focus:outline-none"
            // tabIndex="0"
          >
            <div className="">
              {/* Header  - Model Title */}
              <div className="mx-auto max-w-7xl">
                <Header
                  color="bg-white"
                  isModel={true}
                  title={title}
                  titleSize={"text-lg lg:text-2xl"}
                ></Header>
              </div>

              {/* Subheader */}
              <div className="hidden lg:block">
                <Subheader station={station} h1Text={title}></Subheader>
              </div>

              {/* Model Description */}
              <div className="px-4 mx-auto max-w-7xl xl:px-6 lg:px-8">
                <p dangerouslySetInnerHTML={{ __html: description }} />
              </div>

              {/* Station Header */}
              <div className="px-4 mx-auto max-w-7xl xl:px-6 lg:px-8">
                <div>
                  {/* Station Header */}
                  {station && (
                    <div className="mb-16 xl:mb-18 lg:mb-20">
                      <DashboardStationHeader
                        station={station}
                        data={data}
                        isLoading={isLoading}
                      ></DashboardStationHeader>
                    </div>
                  )}

                  {/* Station Selection Map */}
                  {showMap && (
                    <div
                      className="mb-16 xl:mb-18 lg:mb-20"
                      aria-label="NEWA stations map"
                    >
                      <h2 className="mb-3 font-semibold text-gray-600 xl:mb-5 lg:mb-6 lg:text-2xl">
                        Station Selection Map
                      </h2>
                      <div
                        className="overflow-hidden rounded-lg shadow-lg"
                        aria-hidden="true"
                      >
                        <Map
                          station={station}
                          setStation={setStation}
                          allStations={stationList}
                          geoJSON={geoJSON}
                        ></Map>
                      </div>
                    </div>
                  )}

                  {isVisible && isMesonet && (
                    <div className="max-w-full px-4 py-6 mx-auto mb-16 text-base text-gray-700 bg-white rounded-md shadow-lg sm:mb-18 md:mb-20">
                      Data for this location are available directly from New
                      York State Mesonet{" "}
                      <a
                        href="http://www.nysmesonet.org/weather/requestdata"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>{" "}
                      as part of a formal agreement between University at Albany
                      and Cornell University.
                    </div>
                  )}

                  {isLoading && (
                    <div className="flex items-center justify-center w-full">
                      <HashLoader
                        size={61}
                        color={"#1987C2"}
                        loading={isLoading}
                      />
                    </div>
                  )}

                  {/* Results Table */}
                  {isVisible && isTableData && (
                    <div className="mb-16 sm:mb-18 md:mb-20">
                      <ResultsTable
                        station={station}
                        modelData={{
                          title: degreeDayType.label,
                        }}
                        data={mData.dailyDataWithGdd}
                        startDate={startDate}
                        endDate={endDate}
                      ></ResultsTable>
                    </div>
                  )}

                  {/* Results Graph */}
                  {isVisible && isGraphData && (
                    <div className="mb-16 sm:mb-18 md:mb-20">
                      <ResultsGraph
                        modelData={degreeDayType}
                        data={mData.dailyDataWithGdd}
                        endDate={endDate}
                      ></ResultsGraph>
                    </div>
                  )}

                  {/* Footer */}
                  <div className="mt-16 sm:mt-18 md:mt-20">
                    <Footer
                      color="bg-secondary-800"
                      tutorialLink="https://newa.zendesk.com/hc/en-us/articles/1500003853241"
                      station={station}
                    ></Footer>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
