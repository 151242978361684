import { format } from 'date-fns'
import React from 'react'
import { CSVLink } from 'react-csv'
import ScrollableFeed from 'react-scrollable-feed'

export default function ResultsTable({
  data,
  modelData,
  station,
  startDate,
  endDate,
}) {
  let csvData = data.map((day) => {
    return {
      Date: day.dateDisplay,
      'Daily DD': day.dd,
      Cumulative: day.gdd,
    }
  })

  if (!data) return null
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          {modelData.title}
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`${modelData.title}.csv`}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex items-center">
          <span className="inline-block py-2 mr-4">
            <a
              className="text-xs sm:text-sm"
              href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forecast Details
            </a>
          </span>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-y-auto align-middle border-b border-gray-200 shadow h-128 sm:rounded-lg">
            <ScrollableFeed>
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 bg-secondary-800">
                      Date
                    </th>
                    <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-r border-gray-200 bg-secondary-800">
                      Daily
                    </th>
                    <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-gray-200 bg-secondary-800">
                      From {format(startDate, 'MMM d')}
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {data.map((day) => {
                    return (
                      <tr key={day.dayOfYear} className={`text-center`}>
                        <td
                          className={`text-xs sm:text-sm px-6 h-4 border-b border-gray-200 leading-6 text-gray-700`}
                        >
                          <span className="flex flex-col items-center">
                            {format(day.date, 'LLL d')}
                            <small className="mt-1 font-bold text-gray-500">
                              {day.isForecast ? 'Forecast' : ''}
                            </small>
                          </span>
                        </td>
                        <td
                          className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}
                        >
                          {day.dd}
                        </td>
                        <td
                          className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6`}
                        >
                          <span
                            className={`rounded w-14 sm:w-20 py-1 inline-block`}
                          >
                            {day.gdd}
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </ScrollableFeed>
          </div>
        </div>
      </div>
    </div>
  )
}
