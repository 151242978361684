import { differenceInDays } from 'date-fns'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function DateRange({
  startYear,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  function handleStartDate(date) {
    const numOfDays = differenceInDays(endDate, date)
    if (endDate) {
      if (numOfDays > 395 || numOfDays < 0) {
        setEndDate(null)
      }
    }
    setStartDate(date)
  }

  function handleEndDate(date) {
    if (startDate) {
      if (differenceInDays(date, startDate) > 395) {
        setStartDate(null)
      }
    }
    setEndDate(date)
  }
  return (
    <>
      <span className="block mb-1 text-sm font-extrabold leading-5 text-secondary-800">
        Start Date
      </span>
      <DatePicker
        className="px-2 py-1.5 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500"
        selected={startDate}
        onChange={(date) => handleStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        minDate={new Date(startYear, 0, 1, 0)}
        maxDate={new Date()}
        showYearDropdown
      />
      <span className="block my-4 mb-1 text-sm font-extrabold leading-5 text-secondary-800">
        End Date
      </span>
      <DatePicker
        className="px-2 py-1.5 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500"
        selected={endDate}
        onChange={(date) => handleEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={new Date()}
        showYearDropdown
      />
    </>
  )
}
