import React from 'react'
import Select from 'react-select'
import { useAuth0 } from '../context/react-auth0-spa'
import PlaneSvg from '../svg/plane.svg'

const customStylesDropdown = {
  option: (provided) => ({
    ...provided,
    textAlign: 'left',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 6,
  }),
  input: (provided) => ({
    ...provided,
    width: '100%',
    textAlign: 'left',
  }),
}

const groupBadgeStyles = {
  backgroundColor: '#fff',
  borderRadius: '1em',
  color: '#cbd5e0',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data) => (
  <div className="flex justify-between text-gray-500">
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

const formatOptionLabel = ({ name, state, activeStatus, id }) => {
  const network = id.split(' ')[1]
  return (
    <div className={`flex items-center justify-between text-sm`}>
      <div className="flex-auto text-left truncate">
        {activeStatus ? (
          <span
            aria-label="online NEWA weather station"
            className="flex-shrink-0 inline-block w-2 h-2 mr-2 bg-green-500 rounded-full"
          ></span>
        ) : (
          <span
            aria-label="offline NEWA weather station"
            className="flex-shrink-0 inline-block w-2 h-2 mr-2 bg-red-500 rounded-full"
          ></span>
        )}
        <span className={activeStatus ? `` : `opacity-50`}>{name}, </span>
        <span className={activeStatus ? `font-bold` : `opacity-40`}>
          {state}
        </span>
      </div>
      <div className={activeStatus ? `ml-2 mr-1` : `opacity-50 ml-2 mr-1`}>
        {network === 'icao' && (
          <PlaneSvg className="w-3 h-3" alt="airport newa station"></PlaneSvg>
        )}
      </div>
    </div>
  )
}

function StationsDropdownV2({
  options,
  value,
  setValue,
  setSidebarOpen = () => {},
  isMobile = false,
  isProfile = false,
}) {
  const { user, setUser } = useAuth0()

  function handleSetSelectedStation(station) {
    if (isMobile) setSidebarOpen(false)
    if (station.activeStatus) setValue(station)
    if (isProfile) setFavStations(station)
  }

  function setFavStations(station) {
    if (Object.keys(user).includes('favoriteStations')) {
      const favoriteStations = user.favoriteStations
      const isStationAlreadyInFavorite = favoriteStations.find(
        (stn) => stn.id === station.id,
      )
      if (!isStationAlreadyInFavorite && station.activeStatus) {
        setUser({ ...user, favoriteStations: [...favoriteStations, station] })
      }
    } else {
      setUser({ ...user, favoriteStations: [station] })
    }
  }

  return (
    <Select
      id="stations"
      aria-label="stations"
      value={value}
      placeholder={'Weather Stations'}
      isSearchable
      options={options}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      onChange={(stn) => handleSetSelectedStation(stn)}
      getOptionValue={(option) => option['name']}
      styles={customStylesDropdown}
      theme={(theme) => ({
        ...theme,
        borderRadius: 6,
        colors: {
          ...theme.colors,
          primary25: '#edf2f7',
          primary50: '#86BFDF',
          primary: '#718096',
          // neutral20: "#718096",
        },
      })}
    ></Select>
  )
}

export default StationsDropdownV2
