import {format} from 'date-fns'
import {
  baskervilleEmin,
  fahrenheitToCelcius,
  simpleAve,
} from '../../utils/helpers'

export default function degreeDayCalculatorLogic(data, degreeDayType) {
  const {base, formula, unit, label} = degreeDayType

  let gdd = 0
  const dailyDataWithGdd = data.dailyData.map(day => {
    let dd = 'N/A'
    if (day.mint !== 'M' && day.maxt !== 'M') {
      let minT = day.mint
      let maxT = day.maxt
      if (unit === 'C') {
        minT = fahrenheitToCelcius(day.mint)
        maxT = fahrenheitToCelcius(day.maxt)
      }

      if (label === 'Degree Days - 86/50˚F') {
        if (minT < 50) minT = 50
        if (maxT > 86) maxT = 86
      }

      if (formula === 'BE') {
        dd = baskervilleEmin(minT, maxT, base)
      } else {
        dd = simpleAve(minT, maxT, base)
      }
      gdd += dd
    }
    const {date, dayOfYear, isForecast} = day
    return {
      date,
      dayOfYear,
      isForecast,
      dateDisplay: format(day.date, 'MM/dd/yyyy'),
      dd: Math.round(dd),
      gdd: dd === 'N/A' ? 'N/A' : Math.round(gdd),
    }
  })

  return {dailyDataWithGdd}
}
