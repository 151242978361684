import isToday from 'date-fns/isToday'
import { useQuery } from 'react-query'
import { fetchStationData } from '../fetchData'
import { convertDateToStringForAPI } from '../helpers'

export default function useFetchStationDataAWDQ(
  station = null,
  startDate = null,
  endDate = null,
) {
  let sdate = null
  let edate = null
  const enabled = station !== null && startDate !== null && endDate !== null
  if (enabled) {
    if (startDate <= endDate) {
      sdate = convertDateToStringForAPI(startDate, '00')
      if (isToday(endDate)) {
        edate = 'now'
      } else {
        edate = convertDateToStringForAPI(endDate, '23')
      }
    }
  }

  const body = {
    sid: station ? station.id : '',
    sdate: sdate ? sdate : '',
    edate: edate ? edate : '',
    extraelems: station ? station.extraelems : '',
  }

  const { data, isLoading, isError } = useQuery(
    `${body.sdate}-${body.edate} (${body.sid})`,
    () => fetchStationData(body),
    {
      refetchOnWindowFocus: false,
      enabled,
      refetchOnMount: false,
      cacheTime: 900000, // data remains in memory for 15 minutes
    },
  )

  return { data, isLoading, isError }
}
